<template>
  <div class="wrapper">
    <a
      @click="/*value ? null :*/ disabled ? false : $emit('change')"
      class="click-cursor"
    >
      <div class="imgs" v-if="!spacer" :class="{ disabled: this.disabled }">
        <slot>
          <img
            src="@/assets/product-placeholder.png"
            class="img-fluid thumbnail"
            draggable="false"
          />
        </slot>
        <div class="selected-icon" v-if="value">
          <img
            src="@/assets/popdotconfig/vinkje.png"
            class="img-fluid thumbnail"
            draggable="false"
          />
        </div>
      </div>
    </a>
    <!--<b-input-group class="mt-2" v-if="isProduct">
      <b-button @click="$emit('removePopdot')" class="productButton mr-1"
        >-</b-button
      >
      <b-button class="productButton mr-1">+</b-button>
      <b-form-input size="md" class="p">0</b-form-input>
    </b-input-group> -->
    <p v-if="!spacer" class="text-center productTitle pt-1 text-uppercase">
      {{ title }}
      <template v-if="!!subTitle">
        <span class="productSubtitle"> ({{ subTitle }}) </span>
      </template>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    subTitle: String,
    spacer: Boolean,
    isProduct: Boolean,
    value: Boolean,
    disabled: Boolean,
  },

  data() {
    return {};
  },

  mounted() {
    // this.selected = this.value;
  },

  watch: {
    // value: function (val) {
    //   this.selected = val;
    // },
    // selected: function (val) {
    //   this.$emit("input", val);
    // },
  },
};
</script>

<style lang="scss" scoped>
@import "@/custom.scss";
.thumbnail {
  border-radius: 0.4em;
  cursor: pointer;
}

.productTitle {
  font-family: $product-font;
  font-size: 0.8em;
  color: $product-font-colour;
  user-select: none;
}

.productSubtitle {
  text-transform: none;
  white-space: nowrap;
}

.wrapper {
  width: 31%;
}

.imgs {
  position: relative;
}

.selected-icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 3em;
}

.productButton {
  background-color: #d6d6d6;
  border: none;
  width: 1.5em;
}

.disabled > .thumbnail {
  opacity: 0.3;
  cursor: not-allowed;
}
</style>
